import ros from './rosConnexion.js';

/**
 * RosSwitch Class
 *
 * The RosSwitch class provides methods to change the state of rail switches by publishing messages to a ROS topic.
 * It uses a global publisher to avoid redefining the publisher for every message sent.
 */
export default class RosSwitch {
	static publisher;

	/**
	 * Changes the state of a rail switch by sending the data to the ROS topic.
	 * @param {number} number - The number of the rail switch to be changed.
	 * @param {boolean} boolean - The state to set the rail switch to (true for one direction, false for the other).
	 */
	static changeSwitch(number, boolean) {
		RosSwitch.publisher = new ROSLIB.Topic({
			ros: ros,
			name: '/switch_control',
			messageType: 'std_msgs/String',
		});
		const switchRails = new ROSLIB.Message({
			data: number + '=' + boolean,
		});
		RosSwitch.publisher.publish(switchRails);
	}
}
