/**
 * Toast class that manages the display of toast notifications across the interface.
 */
export default class Toast {
	static errorComponent;
	static successComponent;

	/**
	 * Initializes the toast components.
	 * @param {HTMLElement} errorComponent - The HTML element for displaying error toasts.
	 * @param {HTMLElement} successComponent - The HTML element for displaying success toasts.
	 */
	static initToast(errorComponent, successComponent) {
		Toast.errorComponent = errorComponent;
		Toast.successComponent = successComponent;
	}

	/**
	 * Displays an error message.
	 * @param {string} title - Error message title.
	 */
	static displayError(title) {
		Toast.displayToast(title, Toast.errorComponent);
	}

	/**
	 * Displays a success message.
	 * @param {string} title - Success message title.
	 */
	static displaySuccess(title) {
		Toast.displayToast(title, Toast.successComponent);
	}

	/**
	 * Displays a toast message.
	 * @param {string} title - The message title to be displayed in the toast.
	 * @param {HTMLElement} element - The HTML element used for displaying the toast.
	 * @private
	 */
	static displayToast(title, element) {
		const titleSelector = element.querySelector('span');
		element.classList.add('active');
		titleSelector.innerHTML = title;
		setTimeout(() => {
			element.classList.remove('active');
		}, 4000);
	}
}
