/**
 * Modal Class
 *
 * The Modal class is designed to manage the behavior and interactions within a modal dialog.
 * It provides methods to toggle the modal's visibility, handle checkbox changes, and manage the addition of new colors.
 */
export default class Modal {
	modalElement;
	addButtonElement;

	/**
	 * Constructor
	 *
	 * Initializes a new instance of the Modal class with the specified modal and add button elements.
	 * Sets up event listeners for handling interactions within the modal.
	 *
	 * @param {HTMLElement} modalElement - The HTML element representing the modal.
	 * @param {HTMLElement} addButtonElement - The HTML element representing the button that toggles the modal.
	 */
	constructor(modalElement, addButtonElement) {
		this.modalElement = modalElement;
		this.addButtonElement = addButtonElement;

		const colorBoxes = this.modalElement.querySelectorAll(
			'.colorsContainer .color'
		);
		const closeButton = this.modalElement.querySelector('.closeButton');
		const addColorButton = this.modalElement.querySelector('.chooseColor');

		colorBoxes.forEach(box => {
			box.addEventListener('change', event =>
				this.handleCheckBox(event, colorBoxes, addColorButton)
			);
		});

		this.addButtonElement.addEventListener('click', () => this.toggleModal());

		closeButton.addEventListener('click', () => this.toggleModal());

		addColorButton.addEventListener('click', event =>
			this.handleNewColor(event, addColorButton, colorBoxes)
		);
	}

	/**
	 * Toggles the visibility of the modal.
	 */
	toggleModal() {
		if (this.modalElement.classList.contains('active')) {
			this.modalElement.classList.remove('active');
		} else {
			this.modalElement.classList.add('active');
		}
	}

	/**
	 * Handles the checkbox change event within the modal.
	 * Ensures only one checkbox is checked at a time and updates the add color button state.
	 *
	 * @param {Event} event - The event object.
	 * @param {NodeList} colorBoxes - The list of color checkboxes.
	 * @param {HTMLElement} addColorButton - The button to add a new color.
	 */
	handleCheckBox(event, colorBoxes, addColorButton) {
		event.preventDefault();
		const colorInputContainer = this.modalElement.querySelector(
			'.colorInputContainer'
		);
		if (event.currentTarget.checked) {
			colorBoxes.forEach(box => {
				if (box.checked) {
					box.checked = false;
				}
			});
			event.currentTarget.checked = true;
		} else {
			event.currentTarget.checked = false;
		}
		if (colorInputContainer.classList.contains('active')) {
			colorInputContainer.classList.remove('active');
			addColorButton.innerHTML = `
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" width="15px" height="15px" >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg> 
			`;
		}
	}

	/**
	 * Handles the add color button click event.
	 * Toggles the state of the color input container and updates the add color button icon.
	 *
	 * @param {Event} event - The event object.
	 * @param {HTMLElement} addColorButton - The button to add a new color.
	 * @param {NodeList} colorBoxes - The list of color checkboxes.
	 */
	handleNewColor(event, addColorButton, colorBoxes) {
		event.preventDefault();
		const colorInput = this.modalElement.querySelector('.colorInputContainer');
		colorBoxes.forEach(box => {
			if (box.checked) {
				box.checked = false;
			}
		});
		let path = ``;
		if (colorInput.classList.contains('active')) {
			colorInput.classList.remove('active');
			path = `<path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />`;
		} else {
			colorInput.classList.add('active');
			path = `<path fill-rule="evenodd" d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />`;
		}
		addColorButton.innerHTML = `
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" width="15px" height="15px" >
                    ${path}
                </svg> 
			`;
	}
}
