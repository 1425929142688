import Command from './Command.js';

/**
 * TruckCommand Class
 *
 * The TruckCommand class extends the Command class, adding specific properties and behavior for a truck controller.
 * It inherits the ability to store and convert the configuration of a controller into an HTML representation,
 * while adding truck-specific attributes and behaviors.
 */
export default class TruckCommand extends Command {
	/**
	 * Constructor
	 *
	 * Initializes a new instance of the TruckCommand class with the specified dcc and color,
	 * and sets the device type to 'Camion'.
	 *
	 * @param {string} dcc - The unique identifier for the controller used.
	 * @param {string} color - The color identifier used to style and identify the controller.
	 */
	constructor(dcc, color) {
		super(dcc, color);
		this.device = 'Camion';
	}

	/**
	 * Generates an HTML string that represents the truck controller.
	 * This HTML includes a label, a vertically oriented range input, and two buttons (stop and trash) with specific styling.
	 *
	 * @returns {string} A string of HTML representing the truck controller.
	 */
	commandToHtml() {
		return super.commandToHtml();
	}
}
