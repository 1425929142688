import RosReachStacker from '../ros/RosReachStacker.js';

/**
 * Joystick Class
 *
 * The Joystick class provides a graphical interface to control a reachstacker's movement speed and angle.
 * It allows users to interact with a canvas-based joystick to set these parameters visually.
 */
export default class Joystick {
	containerElement;
	dcc;
	color;
	angle;
	device;
	context;
	canvas;

	width;
	height;
	radius;
	x_orig;
	y_orig;
	coord;
	paint;

	/**
	 * Creates an instance of Joystick.
	 * @param {string} dcc - Device control center identifier.
	 * @param {string} color - Color code for the joystick.
	 * @param {HTMLElement} containerElement - HTML element to contain the joystick interface.
	 */
	constructor(dcc, color, containerElement) {
		this.dcc = dcc;
		this.color = color;
		this.speed = 0;
		this.containerElement = containerElement;
		this.device = 'ReachStacker';
		this.angle = 0;

		this.coord = { x: 0, y: 0 };
		this.paint = false;

		// Insert joystick HTML into the container element
		this.containerElement.insertAdjacentHTML(
			'beforeend',
			this.joystickToHtml()
		);

		// Add canvas to the DOM and initialize canvas-related properties
		this.addCanvasToDOM();

		// Retrieve the canvas element and its context
		this.canvas = this.containerElement.querySelector(
			`#joystick${this.dcc} canvas`
		);
		this.context = this.canvas.getContext('2d');

		// Resize canvas and draw initial joystick position
		this.resize();

		// Add event listeners for mouse interactions
		this.canvas.addEventListener('mousedown', event =>
			this.startDrawing(event)
		);
		this.canvas.addEventListener('mouseup', event => this.stopDrawing(event));
		this.canvas.addEventListener('mousemove', event => this.draw(event));

		// Add event listeners for touch interactions (commented out for simplicity)
		/* this.canvas.addEventListener('touchstart', event =>
			this.startDrawing(event)
		);
		this.canvas.addEventListener('touchend', event => this.stopDrawing(event));
		this.canvas.addEventListener('touchcancel', event =>
			this.stopDrawing(event)
		);
		this.canvas.addEventListener('touchmove', event => this.draw(event)); */

		//window.addEventListener('resize', event => this.resize(event));
	}

	/**
	 * Adds a canvas element to the DOM inside the joystick container.
	 */
	addCanvasToDOM() {
		const container = document.querySelector(`#joystick${this.dcc}`);
		const canvasElement = document.createElement('canvas');
		canvasElement.width = 100;
		canvasElement.height = 100;
		container.appendChild(canvasElement);
	}

	/**
	 * Generates the HTML for the joystick interface.
	 * @returns {string} HTML markup for the joystick interface.
	 */
	joystickToHtml() {
		return `<div class="joystick">
					<div class="joystick-info" id="joystick${this.dcc}">
						<div class="text">
							<p>${this.device.charAt(0)}-${this.color.split('_')[0]}</p>
							<p class="joystick-data">
								Speed: <span class="speed">0</span>
								Angle: <span class="angle">0</span>
							</p>
							</div>
					</div>
				</div>`;
	}

	/**
	 * Resizes the canvas based on the radius and redraws the joystick.
	 */
	resize() {
		this.radius = 50;
		this.width = this.radius * 6;
		this.height = this.radius * 6;
		this.context.canvas.width = this.width;
		this.context.canvas.height = this.height;
		this.x_orig = this.width / 2;
		this.y_orig = this.height / 2;
		this.background();
		this.joystick(this.x_orig, this.y_orig);
	}

	/**
	 * Draws the background circle of the joystick.
	 */
	background() {
		this.context.beginPath();
		this.context.arc(
			this.x_orig,
			this.y_orig,
			this.radius + 20,
			0,
			Math.PI * 2,
			true
		);
		this.context.fillStyle = '#ECE5E5';
		this.context.fill();
	}

	/**
	 * Draws the joystick handle at a specific position.
	 * @param {number} x - X-coordinate of the joystick handle.
	 * @param {number} y - Y-coordinate of the joystick handle.
	 */
	joystick(x, y) {
		this.context.beginPath();
		this.context.arc(x, y, this.radius, 0, Math.PI * 2, true);
		this.context.fillStyle = `#${this.color.split('_')[1]}`;
		this.context.fill();
		this.context.strokeStyle = `#${this.color.split('_')[1].substring(0, 2)}${(parseInt(this.color.split('_')[1].substring(2, 4), 16) - 20).toString(16)}${this.color.split('_')[1].substring(4, 6)}`;
		this.context.lineWidth = 8;
		this.context.stroke();
	}

	/**
	 * Retrieves the mouse or touch position relative to the canvas.
	 * @param {Event} event - Mouse or touch event object.
	 */
	getPosition(event) {
		const rect = this.canvas.getBoundingClientRect();
		const mouse_x = event.clientX || event.touches[0].clientX;
		const mouse_y = event.clientY || event.touches[0].clientY;
		this.coord.x = mouse_x - rect.left;
		this.coord.y = mouse_y - rect.top;
	}

	/**
	 * Checks if the joystick handle is within the joystick circle.
	 * @returns {boolean} True if the handle is within the circle, false otherwise.
	 */
	isItInTheCircle() {
		const current_radius = Math.sqrt(
			Math.pow(this.coord.x - this.x_orig, 2) +
				Math.pow(this.coord.y - this.y_orig, 2)
		);
		return this.radius >= current_radius;
	}

	/**
	 * Starts drawing the joystick handle at the position of the mouse or touch event.
	 * @param {Event} event - Mouse or touch event object.
	 */
	startDrawing(event) {
		this.paint = true;
		this.getPosition(event);
		if (this.isItInTheCircle()) {
			this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.background();
			this.joystick(this.coord.x, this.coord.y);
			this.draw(event);
		}
	}

	/**
	 * Stops drawing the joystick handle and resets it to the center.
	 */
	stopDrawing() {
		this.paint = false;
		this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
		this.background();
		this.joystick(this.width / 2, this.height / 2);
		this.containerElement.querySelector(
			`#joystick${this.dcc} .speed`
		).innerText = 0;
		this.containerElement.querySelector(
			`#joystick${this.dcc} .angle`
		).innerText = 0;
		setTimeout(() => this.stopReachStacker(), 250);
		RosReachStacker.moveReachStacker(0, 0, this.dcc);
	}

	/**
	 * Stops the reachstacker movement by sending zero speed and angle.
	 */
	stopReachStacker() {
		RosReachStacker.moveReachStacker(0, 0, this.dcc);
	}

	/**
	 * Draws the joystick handle at the position of the mouse or touch event and calculates speed and angle.
	 * @param {Event} event - Mouse or touch event object.
	 */
	draw(event) {
		if (this.paint) {
			this.getPosition(event);
			this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
			this.background();

			const angle = Math.atan2(
				this.coord.y - this.y_orig,
				this.coord.x - this.x_orig
			);

			let x, y;
			if (this.isItInTheCircle()) {
				x = this.coord.x;
				y = this.coord.y;
			} else {
				x = this.radius * Math.cos(angle) + this.x_orig;
				y = this.radius * Math.sin(angle) + this.y_orig;
			}
			this.joystick(x, y);

			const speed = (-1 * Math.round(y - this.y_orig)) / 50;
			const speed_angle = Math.round(x - this.x_orig) / 50;

			this.containerElement.querySelector(
				`#joystick${this.dcc} .speed`
			).innerText = speed;
			this.containerElement.querySelector(
				`#joystick${this.dcc} .angle`
			).innerText = speed_angle;

			RosReachStacker.moveReachStacker(speed, speed_angle * -1, this.dcc);
		}
	}
}
