import ros from './rosConnexion.js';

/**
 * RosTrain Class
 *
 * The RosTrain class provides methods to create and control trains by publishing messages to specific ROS topics.
 * It uses a global publisher to avoid redefining the publisher for every message sent.
 */
export default class RosTrain {
	static publisher;

	/**
	 * Creates a train by sending the color and number to the ROS topic /train_creator.
	 * @param {string} color - The color identifier for the train.
	 * @param {number} number - The number identifier for the train.
	 */
	static createTrain(color, number) {
		RosTrain.publisher = new ROSLIB.Topic({
			ros: ros,
			name: '/train_creator',
			messageType: 'std_msgs/String',
		});
		const createTrain = new ROSLIB.Message({
			data: color + '=' + number,
		});
		RosTrain.publisher.publish(createTrain);
	}

	/**
	 * Manages the speed of a train by sending the speed value to the corresponding ROS topic.
	 * @param {number} speed - The speed value to set for the train.
	 * @param {string} color - The color identifier for the train.
	 */
	static moveTrain(speed, color) {
		RosTrain.publisher = new ROSLIB.Topic({
			ros: ros,
			name: '/' + color + '/command',
			messageType: 'std_msgs/String',
		});
		const moveTrain = new ROSLIB.Message({
			data: 'speed=' + speed,
		});
		RosTrain.publisher.publish(moveTrain);
	}

	/**
	 * Reverses the direction of a train by sending a reverse command to the corresponding ROS topic.
	 * @param {string} color - The color identifier for the train.
	 */
	static reverseTrain(color) {
		RosTrain.publisher = new ROSLIB.Topic({
			ros: ros,
			name: '/' + color + '/command',
			messageType: 'std_msgs/String',
		});
		const reverseTrain = new ROSLIB.Message({
			data: 'reverse',
		});
		RosTrain.publisher.publish(reverseTrain);
	}

	/**
	 * Deletes a train by sending the DCC number to the ROS topic /train_destructor.
	 * @param {number} number - The number identifier for the train.
	 */
	static deleteTrain(number) {
		RosTrain.publisher = new ROSLIB.Topic({
			ros: ros,
			name: '/train_destructor',
			messageType: 'std_msgs/Int16',
		});
		const deleteTrain = new ROSLIB.Message({
			data: number,
		});
		RosTrain.publisher.publish(deleteTrain);
	}

	/* 	// une variante de move_train_function pour arreter le train -- non utiisé car redondance --
	static stopTrainRos(color) {
		publisher = new ROSLIB.Topic({
			ros: ros,
			name: '/' + color + '/command',
			messageType: 'std_msgs/String',
		});
		const stopTrain = new ROSLIB.Message({
			data: 'speed=0',
		});
		publisher.publish(stopTrain);
	} */
}
