import RosSwitch from '../ros/RosSwitch.js';

// Array containing positions and configurations of switches
const switchesData = [
	{
		id: 10,
		top: 91.5,
		left: 9,
		rot: 'hor',
		default_side: 'r',
		through: 'enter',
	},
	{
		id: 11,
		top: 91.5,
		left: 45.5,
		rot: 'hor',
		default_side: 'r',
		through: 'exit',
	},
	{
		id: 12,
		top: 91.5,
		left: 48.5,
		rot: 'hor',
		default_side: 'r',
		through: 'enter',
	},
	{
		id: 13,
		top: 91.5,
		left: 82,
		rot: 'hor',
		default_side: 'r',
		through: 'exit',
	},
	{
		id: 14,
		top: 85.5,
		left: 96.15,
		rot: 'ver',
		default_side: 'r',
		through: 'exit',
	},
	{
		id: 15,
		top: 16,
		left: 87.6,
		rot: 'ver',
		default_side: 'r',
		through: 'enter',
	},
	{
		id: 16,
		top: 0.9,
		left: 79.5,
		rot: 'hor',
		default_side: 'r',
		through: 'enter',
	},
	{
		id: 17,
		top: 0.9,
		left: 71.5,
		rot: 'hor',
		default_side: 'r',
		through: 'exit',
	},
	{
		id: 18,
		top: 0.9,
		left: 68.5,
		rot: 'hor',
		default_side: 'l',
		through: 'exit',
	},
	{
		id: 19,
		top: 0.9,
		left: 28.5,
		rot: 'hor',
		default_side: 'r',
		through: 'enter',
	},
	{
		id: 20,
		top: 0.9,
		left: 24.0,
		rot: 'hor',
		default_side: 'l',
		through: 'enter',
	},
	{
		id: 21,
		top: 15,
		left: -3.5,
		rot: 'ver',
		default_side: 'l',
		through: 'enter',
	},
	{
		id: 22,
		top: 31.2,
		left: 14,
		rot: 'hor',
		default_side: 'l',
		through: 'enter',
	},
	{
		id: 23,
		top: 31.2,
		left: 78,
		rot: 'hor',
		default_side: 'r',
		through: 'exit',
	},
	{
		id: 24,
		top: 16.5,
		left: 96.15,
		rot: 'ver',
		default_side: 'l',
		through: 'enter',
	},
];

/**
 * Switches Class
 *
 * The Switches class manages the display and interaction of switches on an interactive map.
 * It allows the user to visualize and control the switches' states (active/inactive) through a graphical interface.
 */
export default class Switches {
	element;

	/**
	 * Creates an instance of the Switches class.
	 * @param {HTMLElement} element - The HTML element to attach the switches view to.
	 */
	constructor(element) {
		this.element = element;
		this.element.innerHTML = this.setAllSwitches();

		const switches = this.element.querySelectorAll('.aiguillages');
		switches.forEach(aSwitch => {
			aSwitch.addEventListener('click', event => this.handleSwitchClick(event));
		});
	}

	/**
	 * Converts a switch configuration to HTML.
	 * @param {number} idx - Index of the switch in the switchesData array.
	 * @returns {string} HTML representation of the switch.
	 */
	switchToHtml = idx => {
		const { id, top, left, rot, default_side, through } = switchesData[idx];
		let first, second, arrowFirst, arrowSecond;
		if (default_side == 'r') {
			first = 'Left';
			second = 'Right';
		} else {
			first = 'Right';
			second = 'Left';
		}
		if (through == 'enter') {
			if (rot == 'hor') {
				arrowFirst = `d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"`;
				arrowSecond = `d="m4.5 4.5 15 15m0 0V8.25m0 11.25H8.25"`;
			} else {
				arrowFirst = `d="m19.5 4.5-15 15m0 0h11.25m-11.25 0V8.25"`;
				arrowSecond = `d="m4.5 4.5 15 15m0 0V8.25m0 11.25H8.25"`;
			}
		} else {
			if (rot == 'hor') {
				arrowFirst = `d="m19.5 19.5-15-15m0 0v11.25m0-11.25h11.25"`;
				arrowSecond = `d="m19.5 4.5-15 15m0 0h11.25m-11.25 0V8.25"`;
			} else {
				arrowFirst = `d="m19.5 19.5-15-15m0 0v11.25m0-11.25h11.25"`;
				arrowSecond = `d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"`;
			}
		}

		return `
		 <div id="ai-${id}" style="top: ${top}%; left: ${left}%;" class="aiguillages ${rot} active">
				<div class="aiguillages${first}">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" ${arrowFirst}/>
					</svg>
				</div>
				<div class="aiguillages${second}">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" ${arrowSecond}/>
					</svg>
				</div>
			  </div>
	`;
	};

	/**
	 * Generates HTML for all switches.
	 * @returns {string} HTML representation of all switches.
	 */
	setAllSwitches() {
		let html = `<img src="./images/new_circuit_nb.png" />`;
		for (let i = 0; i <= 14; i++) {
			html += this.switchToHtml(i);
		}
		return html;
	}

	/**
	 * Handles click event on a switch by switching the position on the interactive map.
	 * @param {Event} event - Click event object.
	 */
	handleSwitchClick(event) {
		let id = parseInt(event.currentTarget.getAttribute('id').split('-')[1]);
		if (event.currentTarget.classList.contains('active')) {
			event.currentTarget.classList.remove('active');
			RosSwitch.changeSwitch(id, 'False');
		} else {
			event.currentTarget.classList.add('active');
			RosSwitch.changeSwitch(id, 'True');
		}
	}
}
