/**
 * Information Class
 *
 * The Information class manages the display of the information popup for a tab.
 * It provides methods to show and hide the popup associated with the popup element and button element.
 */
export default class Information {
	element;
	button;

	/**
	 * Creates an instance of Information.
	 * @param {HTMLElement} element - The HTML element containing the information.
	 * @param {HTMLElement} button - The button that triggers the display of information.
	 */
	constructor(element, button) {
		this.element = element;
		this.button = button;

		const closeButton = this.element.querySelector('.closeButton');
		closeButton.addEventListener('click', event =>
			this.handleCloseButton(event)
		);

		button.addEventListener('click', event =>
			this.handleButtonInformation(event)
		);
	}

	/**
	 * Handles the click event on the close button to hide the information.
	 * Removes the 'active' class from the element, hiding it from view.
	 * @param {MouseEvent} event - The click event object.
	 */
	handleCloseButton(event) {
		this.element.classList.remove('active');
	}

	/**
	 * Handles the click event on the information button to show the information.
	 * Adds the 'active' class to the element, displaying it to the user.
	 * @param {MouseEvent} event - The click event object.
	 */
	handleButtonInformation(event) {
		this.element.classList.add('active');
	}
}
