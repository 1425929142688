/**
 * Checks if a connection to the ROS bridge server is possible.
 *
 * @param {string} url - The WebSocket URL of the ROS bridge server.
 * @returns {Promise<boolean>} - A promise that resolves to true if the connection is possible, otherwise false.
 */
function checkRosConnection(url) {
	return new Promise(resolve => {
		const socket = new WebSocket(url);

		socket.onopen = () => {
			socket.close();
			resolve(true);
		};

		socket.onerror = () => {
			resolve(false); // Resolving false to indicate the server is not reachable
		};
	});
}

/**
 * Establishes a connection to a ROS (Robot Operating System) server via WebSockets.
 *
 * This script creates an instance of ROSLIB.Ros and connects to a ROS server using a WebSocket connection.
 */
const ros = new ROSLIB.Ros();
const rosBridgeUrl = 'ws://localhost:9090';

checkRosConnection(rosBridgeUrl).then(isAvailable => {
	if (isAvailable) {
		ros.connect(rosBridgeUrl);

		ros.on('connection', () => {
			console.log('Connected to ROS bridge server.');
		});

		ros.on('error', error => {
			console.error('Error connecting to ROS bridge server:', error);
		});

		ros.on('close', () => {
			console.log('Connection to ROS bridge server closed.');
		});
	} else {
		console.error('ROS bridge server is not available.');
	}
});

export default ros;
