/**
 * Command Class
 *
 * The Command class is designed to store and convert the configuration of a controller into an HTML representation.
 * This class encapsulates the properties of the controller and provides a method to generate the corresponding HTML structure.
 */
export default class Command {
	dcc;
	color;
	speed;
	device;

	/**
	 * Constructor
	 *
	 * Initializes a new instance of the Command class with the specified dcc and color, and sets the initial speed to 0.
	 *
	 * @param {string} dcc - The unique identifier for the controller used.
	 * @param {string} color - The color identifier used to style and identify the controller.
	 */
	constructor(dcc, color) {
		this.dcc = dcc;
		this.color = color;
		this.speed = 0;
	}

	/**
	 * Generates an HTML string that represents the controller.
	 * This HTML includes a label, a vertically oriented range input, and two buttons (stop and trash) with specific styling.
	 *
	 * @returns {string} A string of HTML representing the controller.
	 */
	commandToHtml() {
		return `
		 <div class="command">
                <label for="range">${this.device.charAt(0)}-${this.color.split('_')[0]}</label>
                <div class="rangeInfo">
                  <style>
                      #${this.color}[type="range"]::-moz-range-thumb{
                        background: #${this.color.split('_')[1]};
                      }
                      #stopbtn_${this.dcc}:hover{
                        border-color: #${this.color.split('_')[1]};
                        color: #${this.color.split('_')[1]}
                      }
                      #trash_${this.dcc}:hover{
                        border-color: #${this.color.split('_')[1]};
                        color: #${this.color.split('_')[1]};
                      }
                  </style>
                  <input
                  type="range"
                  orient="vertical"
                  id="${this.color}"
                  min="-50" 
                  max="50"
                  value="${this.speed}"
                  data-index="1"
                  />
                  <div>
                    <span id="speed_${this.dcc}">${this.speed}</span>
                  </div>
                </div>
                <div class="commandButtons">
                  <button id="stopbtn_${this.dcc}" class="stopButton">Stop</button>
                  <button id="trash_${this.dcc}" class="trashButton trash">
                    <svg id="trash_${this.dcc}" class="trash" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" width="16px" height="16px">
                      <path id="trash_${this.dcc}" class="trash" stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>                    
                  </button>
                </div>
              </div>
	`;
	}
}
