import View from './View.js';
import Toast from '../component/Toast.js';
import Modal from '../component/Modal.js';
import Information from '../component/Information.js';
import Joystick from '../component/Joystick.js';

/**
 * ReachStackerView Class
 *
 * The ReachStackerView class manages the view and interactions related to reachstacker functionalities.
 * It extends the base View class and integrates functionalities for adding reachstackers and controlling their speed and direction.
 */
export default class ReachStackerView extends View {
	modal;
	joysticks = [];

	/**
	 * Creates an instance of ReachStackerView.
	 * Initializes the modal and sets up the event listener for the add form.
	 * Adds a default reachstacker.
	 *
	 * @param {HTMLElement} element - The HTML element to attach the view to.
	 */
	constructor(element) {
		super(element);

		this.modal = new Modal(
			this.element.querySelector('.addForm'),
			this.element.querySelector('.addCommand')
		);

		this.information = new Information(
			this.element.querySelector('.informationPopup'),
			this.element.querySelector('.informationButton')
		);

		const addForm = this.element.querySelector('.addForm');
		addForm.addEventListener('submit', event => this.handleFormSubmit(event));

		this.addReachStackerManual(4, 'Bleu_337EA9');
	}

	/**
	 * Displays the ReachStackerView.
	 */
	show() {
		super.show();
	}

	/**
	 * Adds a new reachstacker based on the form data.
	 * @param {FormData} formData - The form data containing reachstacker details.
	 */
	addReachStacker(formData) {
		this.addReachStackerManual(
			parseInt(formData.get('dcc')),
			formData.get('color'),
			formData.get('colorInput'),
			formData.get('hexaCode')
		);
	}

	/**
	 * Manually adds a new reachstacker with the provided details.
	 * Creates a new Joystick instance and adds it to the joysticks array.
	 *
	 * @param {number} dcc - The unique identifier for the reachstacker.
	 * @param {string} color - The color identifier used to style and identify the reachstacker.
	 * @param {string} [colorInput] - The optional custom color input provided by the user.
	 * @param {string} [hexaCode] - The optional hexadecimal color code.
	 */
	addReachStackerManual(dcc, color, colorInput, hexaCode) {
		this.joysticks.push(
			new Joystick(
				dcc,
				color ||
					colorInput.replace(' ', '-') +
						'_' +
						hexaCode.substring(1, hexaCode.length),
				this.element.querySelector('.commandsContainer')
			)
		);
	}

	/**
	 * Handles form submission event.
	 * It checks if the values are correct then adds the new command to the array, otherwise displays an error.
	 * @param {Event} event - Submit event object.
	 */
	handleFormSubmit(event) {
		event.preventDefault();
		const addForm = this.element.querySelector('.addForm');
		const data = new FormData(event.currentTarget);
		if (isNaN(data.get('dcc'))) {
			Toast.displayError('La valeur dcc doit être numérique.');
			return;
		}
		if ((!data.get('color') && !data.get('colorInput')) || !data.get('dcc')) {
			Toast.displayError('Veuillez remplir tous les champs');
			return;
		}
		const commandDcc = this.joysticks.find(
			command => command.dcc == data.get('dcc')
		);
		if (commandDcc) {
			Toast.displayError('La valeur dcc est déjà utilisée.');
			return;
		}
		const commandColor = this.joysticks.find(
			command =>
				command.color == data.get('color') ||
				command.color.split('_')[0] == data.get('colorInput').replace(' ', '-')
		);
		if (commandColor) {
			Toast.displayError('La couleur est déjà utilisée.');
			return;
		}

		this.addReachStacker(data);

		event.currentTarget.reset();
		Toast.displaySuccess('ReachStacker bien ajouté :)');
		addForm.classList.remove('active');
	}
}
