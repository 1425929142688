import ros from './rosConnexion.js';

/**
 * RosReachStacker Class
 *
 * The RosReachStacker class provides methods to control reachstackers by publishing messages to specific ROS topics.
 * It uses a global publisher to avoid redefining the publisher for every message sent.
 */
export default class RosReachStacker {
	static publisher;

	/**
	 * Publishes a move command to the reachstacker.
	 * @param {number} speed - The speed at which the reachstacker should move.
	 * @param {number} angle - The angle at which the reachstacker should move.
	 * @param {number} number - The identifier number of the reachstacker.
	 */
	static moveReachStacker(speed, angle, number) {
		RosReachStacker.publisher = new ROSLIB.Topic({
			ros: ros,
			name: `/tquad${number}/tquad/cmd_vel`,
			messageType: 'geometry_msgs/Twist',
		});
		const twist = new ROSLIB.Message({
			linear: {
				x: speed,
				y: angle,
				z: 0.0,
			},
			angular: {
				x: 0.0,
				y: 0.0,
				z: 0.0,
			},
		});
		RosReachStacker.publisher.publish(twist);
	}
}
