import View from './View.js';
import Information from '../component/Information.js';
import RosCrane from '../ros/RosCrane.js';

/**
 * CraneView Class
 *
 * The CraneView class manages the view and interactions related to crane functionalities.
 * It extends the base View class and integrates functionalities for controlling the crane position and magnet.
 */
export default class CraneView extends View {
	currentValue;

	/**
	 * Initializes a new instance of the CraneView class.
	 * @param {HTMLElement} element - The HTML element that contains the crane controls.
	 */
	constructor(element) {
		super(element);

		this.information = new Information(
			this.element.querySelector('.informationPopup'),
			this.element.querySelector('.informationButton')
		);

		this.currentValue = new Map();
		this.currentValue.set('baseValue', 0); // 0 cause i don't know yet how to get the actual value
		this.currentValue.set('craneValue', 0); // 0 cause i don't know yet how to get the actual value
		this.currentValue.set('tackerValue', 0); //0 cause i don't know yet how to get the actual value

		const baseButtons = this.element.querySelectorAll('.baseButton');
		baseButtons.forEach(button => {
			button.addEventListener('click', event =>
				this.handleButtons(event, 'baseValue')
			);
		});

		const craneButtons = this.element.querySelectorAll('.craneButton');
		craneButtons.forEach(button => {
			button.addEventListener('click', event =>
				this.handleButtons(event, 'craneValue')
			);
		});

		const tackerButtons = this.element.querySelectorAll('.tackerButton');
		tackerButtons.forEach(button => {
			button.addEventListener('click', event =>
				this.handleButtons(event, 'tackerValue')
			);
		});

		const magnetButton = this.element.querySelector('.magnetButton');
		magnetButton.addEventListener('click', event =>
			this.handleMagnetButton(event)
		);
	}

	/**
	 * Displays the CraneView.
	 */
	show() {
		super.show();
	}

	/**
	 * Retrieves the value from the input associated with the specified button.
	 * @param {HTMLElement} button - The button element.
	 * @returns {number} - The input value as an integer.
	 */
	getInputValue(button) {
		return parseInt(button.parentElement.querySelector('input').value);
	}

	/**
	 * Updates the current value for the specified type based on the button clicked.
	 * @param {HTMLElement} button - The button element.
	 * @param {string} type - The type of value to update ('baseValue', 'craneValue', or 'tackerValue').
	 */
	setCurrentValue(button, type) {
		let value = this.getInputValue(button);
		if (button.classList.contains('fast')) {
			value *= 2;
		}
		if (button.classList.contains('neg')) {
			value = -value;
		}
		this.currentValue.set(type, this.currentValue.get(type) + value);
	}

	/**
	 * Handles button clicks to update the crane position values and sends the command to the crane.
	 * @param {Event} event - The event object.
	 * @param {string} type - The type of value to update ('baseValue', 'craneValue', or 'tackerValue').
	 */
	handleButtons(event, type) {
		event.preventDefault();
		this.setCurrentValue(event.currentTarget, type);
		RosCrane.moveCrane(
			type.charAt(0).toUpperCase(),
			this.currentValue.get(type)
		);
	}

	/**
	 * Handles the magnet button click to toggle the magnet state and sends the command to the crane.
	 * @param {Event} event - The event object.
	 */
	handleMagnetButton(event) {
		event.preventDefault();
		if (event.currentTarget.classList.contains('active')) {
			event.currentTarget.classList.remove('active');
			RosCrane.switchMagnet(0);
		} else {
			event.currentTarget.classList.add('active');
			RosCrane.switchMagnet(1);
		}
	}
}
