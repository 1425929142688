import View from './View.js';
import Toast from '../component/Toast.js';
import Modal from '../component/Modal.js';
import TrainCommand from '../component/TrainCommand.js';
import RosTrain from '../ros/RosTrain.js';
import Switches from '../component/Switches.js';
import Information from '../component/Information.js';

/**
 * TrainView Class
 *
 * The TrainView class manages the view and interactions related to train functionalities.
 * It extends the base View class and integrates functionalities for adding trains, controlling their speed,
 * reversing their direction, and managing rail switches.
 */
export default class TrainView extends View {
	modal;
	switches;
	information;
	commands = [];

	/**
	 * Creates an instance of TrainView.
	 * Initializes the switches, modal, and event listeners for form submission and commands container interactions.
	 * Adds two default trains.
	 *
	 * @param {HTMLElement} element - The HTML element to attach the view to.
	 */
	constructor(element) {
		super(element);

		this.switches = new Switches(
			this.element.querySelector('.speedAiguillages')
		);

		this.modal = new Modal(
			this.element.querySelector('.addForm'),
			this.element.querySelector('.addCommand')
		);

		this.information = new Information(
			this.element.querySelector('.informationPopup'),
			this.element.querySelector('.informationButton')
		);

		const addForm = this.element.querySelector('.addForm');
		addForm.addEventListener('submit', event => this.handleFormSubmit(event));

		const commandsContainer = this.element.querySelector('.commandsContainer');
		commandsContainer.addEventListener('input', event =>
			this.handleRangeInput(event)
		);
		commandsContainer.addEventListener('mouseup', event =>
			this.handleRangeMouseUp(event)
		);
		commandsContainer.addEventListener('click', event =>
			this.handleCommandClick(event)
		);

		this.addTrainManual(3, 'Rouge_DC423C');
		this.addTrainManual(6, 'Jaune_E8CB63');
	}

	/**
	 * Displays the TrainView.
	 */
	show() {
		super.show();
	}

	/**
	 * Adds a new train based on the form data.
	 * @param {FormData} formData - The form data containing train details.
	 */
	addTrain(formData) {
		this.addTrainManual(
			parseInt(formData.get('dcc')),
			formData.get('color'),
			formData.get('colorInput'),
			formData.get('hexaCode')
		);
	}

	/**
	 * Adds a train manually by creating a new TrainCommand instance and sending data to ROS.
	 * @param {number} dcc - The DCC number of the train.
	 * @param {string} color - The color identifier for the train.
	 * @param {string} colorInput - The color input value.
	 * @param {string} hexaCode - The hexadecimal code for the color.
	 */
	addTrainManual(dcc, color, colorInput, hexaCode) {
		this.commands.push(
			new TrainCommand(
				dcc,
				color ||
					colorInput.replace(' ', '-') +
						'_' +
						hexaCode.substring(1, hexaCode.length)
			)
		);
		RosTrain.createTrain(
			color?.split('_')[0] || colorInput.replace(' ', '-'),
			'' + dcc
		);
		this.commandsContainerToHtml();
	}

	/**
	 * Handles form submission event.
	 * It checks if the values are correct then adds the new command to the array, otherwise displays an error.
	 * @param {Event} event - Submit event object.
	 */
	handleFormSubmit(event) {
		event.preventDefault();
		const addForm = this.element.querySelector('.addForm');
		const data = new FormData(event.currentTarget);
		if (isNaN(data.get('dcc'))) {
			Toast.displayError('La valeur dcc doit être numérique.');
			return;
		}
		if ((!data.get('color') && !data.get('colorInput')) || !data.get('dcc')) {
			Toast.displayError('Veuillez remplir tous les champs');
			return;
		}
		const commandDcc = this.commands.find(
			command => command.dcc == data.get('dcc')
		);
		if (commandDcc) {
			Toast.displayError('La valeur dcc est déjà utilisée.');
			return;
		}
		const commandColor = this.commands.find(
			command =>
				command.color == data.get('color') ||
				command.color.split('_')[0] == data.get('colorInput').replace(' ', '-')
		);
		if (commandColor) {
			Toast.displayError('La couleur est déjà utilisée.');
			return;
		}

		this.addTrain(data);

		event.currentTarget.reset();
		Toast.displaySuccess('Train bien ajouté :)');
		addForm.classList.remove('active');
	}

	/**
	 * Updates the HTML content of the commands container.
	 */
	commandsContainerToHtml() {
		const commandsContainer = this.element.querySelector('.commandsContainer');
		let html = '';
		this.commands.forEach(command => (html += command.commandToHtml()));
		commandsContainer.innerHTML = html;
	}

	/**
	 * Handles input events on range inputs. Updates the speed of the train command.
	 * @param {Event} event - The input event object.
	 */
	handleRangeInput(event) {
		if (event.target.tagName === 'INPUT' && event.target.type === 'range') {
			const command = this.commands.find(
				c => event.target.getAttribute('id') === c.color
			);
			command.speed = parseInt(event.target.value);
			const speedText = event.target.nextElementSibling;
			speedText.innerHTML = `<span id="speed_${command.dcc}">${command.speed}</span>`;
		}
	}

	/**
	 * Handles mouseup events on range inputs. Updates train speed and direction.
	 * @param {Event} event - The mouseup event object.
	 */
	handleRangeMouseUp(event) {
		if (event.target.tagName === 'INPUT' && event.target.type === 'range') {
			const command = this.commands.find(
				c => event.target.getAttribute('id') === c.color
			);
			if (
				(command.speed < 0 && command.reversed == false) ||
				(command.speed > 0 && command.reversed == true)
			) {
				command.reversed = !command.reversed;
				RosTrain.reverseTrain(command.color.split('_')[0]);
			}
			RosTrain.moveTrain(command.speed, command.color.split('_')[0]);
		}
	}

	/**
	 * Handles click events on command buttons (stop and trash). Performs respective actions.
	 * @param {Event} event - The click event object.
	 */
	handleCommandClick(event) {
		if (event.target.classList.contains('stopButton')) {
			const command = this.commands.find(
				c => parseInt(event.target.getAttribute('id').split('_')[1]) === c.dcc
			);
			command.speed = 0;
			RosTrain.moveTrain(command.speed, command.color.split('_')[0]);
			const speed = this.element.querySelector(
				`#speed_${event.target.getAttribute('id').split('_')[1]}`
			);
			const input = this.element.querySelector(`#${command.color}`);
			input.value = 0;
			speed.innerHTML = command.speed;
		}
		if (event.target.classList.contains('trash')) {
			const dcc = parseInt(event.target.getAttribute('id').split('_')[1]);
			this.commands = this.commands.filter(command => command.dcc !== dcc);
			//RosTrain.deleteTrain(dcc); doesn't work in the ROS side
			this.commandsContainerToHtml();
		}
	}
}
