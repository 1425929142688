/**
 * Base class for the views in our application.
 * Allows associating an HTML element with the view and displaying/hiding it.
 */
export default class View {
	/**
	 * HTML element associated with the view
	 */
	element;

	constructor(element) {
		this.element = element;
	}
	/**
	 * Displays the view by adding the `active` CSS class to it
	 */
	show() {
		this.element.classList.add('active');
	}
	/**
	 * Hides the view by removing the `active` CSS class from it
	 */
	hide() {
		this.element.classList.remove('active');
	}
}
