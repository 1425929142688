/**
 * Router class that manages navigation within the application without page reloads.
 * (Single Page Application)
 */
export default class Router {
	/**
	 * Array of routes/views in the application.
	 * @example `Router.routes = [{ path: '/help', view: helpView, title: 'Support' }]`
	 */
	static routes = [];
	static currentRoute;

	static titleElement;
	static #menuElement; // static and private property (#...)

	/**
	 * Setter that specifies the HTML element containing the navigation menu.
	 * Listens for clicks on each link and triggers the `Router.navigate` method.
	 * @param {Element} menuElement - The HTML element containing the menu.
	 * @see Router.handleMenuLinkClick
	 * @see Router.navigate
	 */
	static setMenuElement(menuElement) {
		this.#menuElement = menuElement;

		const menuLinks = this.#menuElement.querySelectorAll('a');
		menuLinks.forEach(link =>
			link.addEventListener('click', event => {
				event.preventDefault();

				const linkHref = event.currentTarget.getAttribute('href');
				Router.navigate(linkHref);
			})
		);
	}
	/**
	 * Displays the view corresponding to `path` in the `routes` array.
	 * @param {String} path - URL of the page to display.
	 * @param {Boolean} skipPushState - Enables/disables pushState (browser's back/forward button management).
	 */
	static navigate(path, skipPushState = false) {
		const route = this.routes.find(route => {
			return route.link === path;
		});
		if (route) {
			if (this.currentRoute) {
				this.currentRoute.view.hide();
				const currentLinkItem =
					this.#menuElement.querySelector(`a[class="active"]`);
				currentLinkItem.classList.remove('active');
			}
			this.currentRoute = route;

			const linkItem = this.#menuElement.querySelector(
				`a[href="${this.currentRoute.link}"]`
			);
			linkItem.classList.add('active');

			this.titleElement.innerHTML = route.name;

			route.view.show();

			if (!skipPushState) {
				window.history.pushState(null, null, path);
			}
		}
	}
}
