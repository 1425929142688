import ros from './rosConnexion.js';

/**
 * RosTruck Class
 *
 * The RosTruck class provides methods to control trucks by publishing messages to specific ROS topics.
 * It uses a global publisher to avoid redefining the publisher for every message sent.
 */
export default class RosTruck {
	static publisher;

	/**
	 * Manages the speed of a truck by sending the speed value to the corresponding ROS topic.
	 * @param {number} speed - The speed value to set for the truck.
	 * @param {string} number - The number identifier for the truck.
	 */
	static moveTruck(speed, number) {
		RosTruck.publisher = new ROSLIB.Topic({
			ros: ros,
			name: '/truck' + number + '/cmd_vel',
			messageType: 'geometry_msgs/Twist',
		});
		const twist = new ROSLIB.Message({
			linear: {
				x: speed / 50 /* speed == 0 ? 0 : speed > 0 ? 1 : -1 */,
				y: 0.0,
				z: 0.0,
			},
			angular: {
				x: 0.0,
				y: 0.0,
				z: 0.0,
			},
		});
		RosTruck.publisher.publish(twist);
	}
}
