/**
 * NavBar Class
 *
 * The NavBar class is designed to manage the behavior and rendering of a navigation bar.
 * This class encapsulates the properties and methods to handle the interactivity and HTML generation of navigation tabs.
 */
export default class NavBar {
	element;

	/**
	 * Constructor
	 *
	 * Initializes a new instance of the NavBar class with the specified HTML element.
	 * Adds event listeners to handle mouseover and mouseleave events.
	 *
	 * @param {HTMLElement} element - The HTML element representing the navigation bar.
	 */
	constructor(element) {
		this.element = element;

		this.element.addEventListener('mouseover', event => {
			this.element.classList.add('active');
		});

		this.element.addEventListener('mouseleave', event => {
			this.element.classList.remove('active');
		});
	}

	/**
	 * Converts a tab configuration into an HTML string.
	 * This HTML includes a link, an SVG icon, and a text label for the tab.
	 *
	 * @param {Object} tab - The tab configuration object.
	 * @param {string} tab.name - The name of the tab.
	 * @param {string} tab.link - The URL the tab links to.
	 * @param {string} tab.size - The size of the SVG viewBox.
	 * @param {string[]} tab.path - An array of SVG path data strings.
	 * @returns {string} A string of HTML representing the tab.
	 */
	tabToHTML(tab) {
		let allPath = [];
		tab.path.forEach(p => {
			allPath.push(`<path d="${p}" />`);
		});
		return `
			<div class="tab">
			<a href="${tab.link}">
				<svg id="link_${tab.name}" width="30px" height="30px" viewBox="${tab.size}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					${allPath}
				</svg>
				<span class="tabText">${tab.name}</span></a>
			</div>
			
		`;
	}

	/**
	 * Sets the HTML for all tabs in the navigation bar.
	 * Iterates through the routes and converts each tab configuration into HTML,
	 * then updates the innerHTML of the navigation tab container.
	 *
	 * @param {Object[]} routes - An array of tab configuration objects.
	 */
	setAllTabs(routes) {
		let res = '';
		routes.forEach(tab => {
			res += this.tabToHTML(tab);
		});

		const navTabContainer = this.element.querySelector('.navTabContainer');
		navTabContainer.innerHTML = res;
	}
}
