import View from './View.js';
import Toast from '../component/Toast.js';
import Modal from '../component/Modal.js';
import Information from '../component/Information.js';
import TruckCommand from '../component/TruckCommand.js';
import RosTruck from '../ros/RosTruck.js';

/**
 * TruckView Class
 *
 * The TruckView class manages the view and interactions related to truck functionalities.
 * It extends the base View class and integrates functionalities for adding trucks and controlling their speed.
 */
export default class TruckView extends View {
	modal;
	commands = [];

	/**
	 * Creates an instance of TruckView.
	 * Initializes the modal and event listeners for form submission and commands container interactions.
	 * Adds two default trucks.
	 *
	 * @param {HTMLElement} element - The HTML element to attach the view to.
	 */
	constructor(element) {
		super(element);

		this.modal = new Modal(
			this.element.querySelector('.addForm'),
			this.element.querySelector('.addCommand')
		);

		this.information = new Information(
			this.element.querySelector('.informationPopup'),
			this.element.querySelector('.informationButton')
		);

		const addForm = this.element.querySelector('.addForm');
		addForm.addEventListener('submit', event => this.handleFormSubmit(event));

		const commandsContainer = this.element.querySelector('.commandsContainer');
		commandsContainer.addEventListener('input', event =>
			this.handleRangeInput(event)
		);
		commandsContainer.addEventListener('mouseup', event =>
			this.handleRangeMouseUp(event)
		);
		commandsContainer.addEventListener('click', event =>
			this.handleCommandClick(event)
		);

		this.addTruckManual(3, 'Rouge_DC423C');
		this.addTruckManual(1, 'Jaune_E8CB63');
	}

	/**
	 * Displays the TruckView.
	 */
	show() {
		super.show();
	}

	/**
	 * Adds a new truck based on the form data.
	 * @param {FormData} formData - The form data containing truck details.
	 */
	addTruck(formData) {
		this.addTruckManual(
			parseInt(formData.get('dcc')),
			formData.get('color'),
			formData.get('colorInput'),
			formData.get('hexaCode')
		);
	}

	/**
	 * Manually adds a truck by creating a new TruckCommand instance and updating the view.
	 * @param {number} dcc - The DCC number of the truck.
	 * @param {string} color - The color identifier for the truck.
	 * @param {string} colorInput - The color input value.
	 * @param {string} hexaCode - The hexadecimal code for the color.
	 */
	addTruckManual(dcc, color, colorInput, hexaCode) {
		this.commands.push(
			new TruckCommand(
				dcc,
				color ||
					colorInput.replace(' ', '-') +
						'_' +
						hexaCode.substring(1, hexaCode.length),
				'Camion'
			)
		);
		this.commandsContainerToHtml();
	}

	/**
	 * Handles form submission event.
	 * It checks if the values are correct then adds the new command to the array, otherwise displays an error.
	 * @param {Event} event - Submit event object.
	 */
	handleFormSubmit(event) {
		event.preventDefault();
		const addForm = this.element.querySelector('.addForm');
		const data = new FormData(event.currentTarget);
		if (isNaN(data.get('dcc'))) {
			Toast.displayError('La valeur dcc doit être numérique.');
			return;
		}
		if ((!data.get('color') && !data.get('colorInput')) || !data.get('dcc')) {
			Toast.displayError('Veuillez remplir tous les champs');
			return;
		}
		const commandDcc = this.commands.find(
			command => command.dcc == data.get('dcc')
		);
		if (commandDcc) {
			Toast.displayError('La valeur dcc est déjà utilisée.');
			return;
		}
		const commandColor = this.commands.find(
			command =>
				command.color == data.get('color') ||
				command.color.split('_')[0] == data.get('colorInput').replace(' ', '-')
		);
		if (commandColor) {
			Toast.displayError('La couleur est déjà utilisée.');
			return;
		}

		this.addTruck(data);

		event.currentTarget.reset();
		Toast.displaySuccess('Camion bien ajouté :)');
		addForm.classList.remove('active');
	}

	/**
	 * Updates the HTML content of the commands container.
	 */
	commandsContainerToHtml() {
		const commandsContainer = this.element.querySelector('.commandsContainer');
		let html = '';
		this.commands.forEach(command => (html += command.commandToHtml()));
		commandsContainer.innerHTML = html;
	}

	/**
	 * Handles input events on range inputs. Updates the speed of the truck command.
	 * @param {Event} event - The input event object.
	 */
	handleRangeInput(event) {
		if (event.target.tagName === 'INPUT' && event.target.type === 'range') {
			const command = this.commands.find(
				c => event.target.getAttribute('id') === c.color
			);
			command.speed = parseInt(event.target.value);
			const speedText = event.target.nextElementSibling;
			speedText.innerHTML = `<span id="speed_${command.dcc}">${command.speed}</span>`;
		}
	}

	/**
	 * Handles mouseup events on range inputs. Updates truck speed.
	 * @param {Event} event - The mouseup event object.
	 */
	handleRangeMouseUp(event) {
		if (event.target.tagName === 'INPUT' && event.target.type === 'range') {
			const command = this.commands.find(
				c => event.target.getAttribute('id') === c.color
			);
			RosTruck.moveTruck(command.speed, command.dcc);
		}
	}

	/**
	 * Handles click events on command buttons (stop and trash). Performs respective actions.
	 * @param {Event} event - The click event object.
	 */
	handleCommandClick(event) {
		if (event.target.classList.contains('stopButton')) {
			const command = this.commands.find(
				c => parseInt(event.target.getAttribute('id').split('_')[1]) === c.dcc
			);
			command.speed = 0;
			RosTruck.moveTruck(command.speed, command.dcc);
			const speed = this.element.querySelector(
				`#speed_${event.target.getAttribute('id').split('_')[1]}`
			);
			const input = this.element.querySelector(`#${command.color}`);
			input.value = 0;
			speed.innerHTML = command.speed;
		}
		if (event.target.classList.contains('trash')) {
			const dcc = parseInt(event.target.getAttribute('id').split('_')[1]);
			this.commands = this.commands.filter(command => command.dcc !== dcc);
			this.commandsContainerToHtml();
		}
	}
}
