import ros from './rosConnexion.js';

/**
 * RosCrane Class
 *
 * The RosCrane class provides methods to control a crane by publishing messages to specific ROS topics.
 * It uses a global publisher to avoid redefining the publisher for every message sent.
 */
export default class RosCrane {
	static publisher;

	/**
	 * Sends a command to move the crane to a specific position.
	 * @param {string} BCT - Specific command identifier.
	 * @param {string} value - Value associated with the command.
	 */
	static moveCrane(BCT, value) {
		RosCrane.publisher = new ROSLIB.Topic({
			ros: ros,
			name: '/craneV1/',
			messageType: 'std_msgs/String',
		});
		const commandsCrane = new ROSLIB.Message({
			data: BCT + '=' + value,
		});
		RosCrane.publisher.publish(commandsCrane);
	}

	/**
	 * Sends a command to switch the magnet state of the crane.
	 * @param {number} value - Magnet state value (0 for inactive, 1 for active).
	 */
	static switchMagnet(value) {
		RosCrane.publisher = new ROSLIB.Topic({
			ros: ros,
			name: '/craneV1/',
			messageType: 'std_msgs/String',
		});
		const magnetState = new ROSLIB.Message({
			data: 'G=' + value,
		});
		RosCrane.publisher.publish(magnetState);
	}
}
